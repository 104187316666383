import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import { GiLightBulb } from "react-icons/gi";
import { GrTechnology } from "react-icons/gr";
import { IoIosGlobe } from "react-icons/io";
import { GoKey } from "react-icons/go";
import { BsPeople } from "react-icons/bs";

import { Container, Row, Col } from "reactstrap";
import { NavMenu } from "./NavMenu";
import { Team } from "./Team";

class App extends Component {
  render() {
    return (
      <div>
        <NavMenu />
        <header>
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12} className="img-wrapper">
                <img src="/images/logo.png" alt="logo" />
              </Col>
              <Col lg={6} md={6} sm={12} xs={12} className="links">
                <h5>An IP commercialization engine</h5>
              </Col>
            </Row>
          </Container>
        </header>
        <section className="text-center">
          <Container>
            <h2>Our view</h2>
            <p className="constraint-center">
              <b>Strong intellectual property</b> allows companies large and
              small to find new paths to market and accelerate revenue outside
              their current business model
            </p>
          </Container>
        </section>
        <section className="text-center">
          <Container>
            <h2>What we do</h2>
            <p className="constraint-center" style={{ maxWidth: 640 }}>
              Create new commercial entities to help small companies{" "}
              <b>scale</b> abroad and help multinationals <b>launch</b>{" "}
              disruptive technology
            </p>
          </Container>
        </section>
        <section className="startups text-center">
          <Container>
            <h2>Our portfolio</h2>
            <Row style={{ marginBottom: 48 }}>
              <Col lg={4} md={6} sm={12} xs={12}>
                <img
                  src="/images/fastsalt.png"
                  alt="logo"
                  style={{ maxHeight: 72 }}
                />
                <p>
                  All natural, additive-free low sodium salt technology, 30%
                  lower than standard salt
                </p>
              </Col>
              <Col lg={4} md={6} sm={12} xs={12}>
                <img
                  src="/images/thermal.png"
                  alt="logo"
                  style={{ maxHeight: 72 }}
                />
                <p>
                  Solid state cooling technology enabling new applications in
                  indoor / outdoor cooling, cold chain and life sciences
                </p>
              </Col>
              <Col lg={4} md={6} sm={12} xs={12}>
                <img
                  src="/images/sonik.png"
                  alt="logo"
                  style={{ maxHeight: 72 }}
                />
                <p>
                  New consumer cooking products based on proprietary sonication
                  technology
                </p>
              </Col>
              {/* <Col lg={3} md={6} sm={12} xs={12}>
                <img src="/images/international.png" alt="logo" />
                <p>
                  Rapid-assembly modular medical, emergency, retail and
                  residential infrastructure
                </p>
              </Col> */}
            </Row>
          </Container>
        </section>
        <section className="about-us">
          <Container>
            <h2 className="text-center">About us</h2>
            <Row>
              <Col lg={4} md={4} sm={12} xs={12}>
                <h5>
                  <GiLightBulb />
                  &nbsp;&nbsp;Opportunity driven
                </h5>
                <p>
                  Our focus is on strong IP not verticals or technology spaces.
                  The collective experience of the team is broad and deep but
                  the common thread that holds us together is a deep
                  appreciation of intellectual property.
                </p>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <h5>
                  <GrTechnology />
                  &nbsp;&nbsp;Technology
                </h5>
                <p>
                  Whether it is solid state physics, biotechnology, or software,
                  our team has experience across Fortune 500s and fast-growing
                  start-ups
                </p>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <h5>
                  <IoIosGlobe />
                  &nbsp;&nbsp;Global markets
                </h5>
                <p>
                  The team has operated businesses in most major economies. We
                  know how to build partnerships that last and position
                  companies for success
                </p>
              </Col>
              <Col lg={2} md={2} sm={12} xs={12} />
              <Col lg={4} md={4} sm={12} xs={12}>
                <h5>
                  <GoKey />
                  &nbsp;&nbsp;Licensing
                </h5>
                <p>
                  We know how to construct license deals that are win-win yet
                  robust enough to stand the test of time
                </p>
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <h5>
                  <BsPeople />
                  &nbsp;&nbsp;People
                </h5>
                <p>
                  Chemistry amongst any team is a key defining factor between
                  success and failure. We’ve learned not to settle and insist on
                  only the best
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="our-partners text-center">
          <Container>
            <h2>Our partners</h2>
            <Row>
              <Col lg={2} md={2} sm={12} xs={12} />
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  src="/images/pg.png"
                  alt="logo"
                  style={{ maxHeight: 72, marginBottom: 32 }}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  src="/images/pepsico.png"
                  alt="logo"
                  style={{ maxHeight: 72, marginBottom: 32 }}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  src="/images/intellectual.png"
                  alt="logo"
                  style={{ maxHeight: 72, marginBottom: 32 }}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  src="/images/phononic.png"
                  alt="logo"
                  style={{ maxHeight: 72, marginBottom: 32 }}
                />
              </Col>
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  src="/images/bryte.png"
                  alt="logo"
                  style={{ maxHeight: 72, marginBottom: 32 }}
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Team />
        </section>
        <footer id="contact">
          <Container>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12} className="img-wrapper">
                <img src="/images/logo.png" alt="logo" />
              </Col>
              <Col lg={6} md={6} sm={12} xs={12} className="links">
                <p>1 George Street, #10-01, Singapore 049145</p>
                <a href="mailto:info@wavefrontvp.com">info@wavefrontvp.com</a>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default App;
