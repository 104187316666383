import React, { Component } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

export class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      topbarTransparent: true,
    };
  }

  componentDidMount() {
    if (window.scrollY < 120) {
      this.setState({ topbarTransparent: true });
    }

    window.onscroll = () => {
      if (window.scrollY > 120) {
        this.setState({ topbarTransparent: false });
      } else {
        this.setState({ topbarTransparent: true });
      }
    };
  }

  render() {
    return (
      <div ref={(input) => (this.navmenu = input)}>
        <Navbar
          color={this.state.topbarTransparent ? "light" : "dark"}
          light
          expand="md"
        >
          <Container>
            <NavbarBrand href="/">
              <img
                src={
                  this.state.topbarTransparent
                    ? "/images/logo-black.png"
                    : "/images/logo.png"
                }
                style={{ maxHeight: 32 }}
                alt="logo"
              />
            </NavbarBrand>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="#contact">Contact</NavLink>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}
