import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";

export class Team extends Component {
  render() {
    return (
      <Container className="team">
        <h2 className="text-center">Team</h2>

        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-tim-londergan.jpg" alt="team-tim" />
            </div>
            <h5>Tim Londergan, PhD</h5>
            <h5>Founder & CEO</h5>
            <div className="desc">
              <p>
                <span>Co-Founder</span>, Operem
              </p>
              <p>
                <span>Portfolio Manager</span>, Intellectual Ventures (IV),
                Invention Development Fund
              </p>
              <p>
                <span>Co-Founder</span>, Lumera (Nasdaq: IDTI)
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-erick-rabins.jpg" alt="team-tim" />
            </div>
            <h5>Erick Rabins, MS</h5>
            <h5>Chief Business Officer</h5>
            <div className="desc">
              <p>
                <span>Managing Consultant</span>, Washington Dental Seed
                Investment Fund
              </p>
              <p>
                <span>Vice President</span>, Allied Minds LLC (LON: ALM)
              </p>
              <p>
                <span>Founder and President</span>, Dentigenix, Inc., (Ivoclar
                Vivadent)
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-kway.jpg" alt="team-kway" />
            </div>
            <h5>Jasmine Kway, PhD</h5>
            <h5>Board Director</h5>
            <div className="desc">
              <p>
                <span>CEO</span>, Singapore Volition, VolitionRx
              </p>
              <p>
                <span>CEO of IP practice</span>, RHT i-Assets Advisory Pte. Ltd.
              </p>
              <p>
                <span>Director</span>, IPOS
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-cui.jpg" alt="team-cui" />
            </div>
            <h5>Jane Cui</h5>
            <h5>Board Director</h5>
            <div className="desc">
              <p>
                <span>Asia Chairwoman</span>, Leica Camera AG
              </p>
              <p>
                <span>Advisor</span>, Muzik, StockX, Core Scientific
              </p>
              <p>
                <span>General Manager</span>, Microsoft
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-nick.jpeg" alt="team-nick" />
            </div>
            <h5>Nick Gibson</h5>
            <h5>Managing Director Japan</h5>
            <div className="desc">
              <p>
                <span>Founder & General Manager</span>, Glydways Japan
              </p>
              <p>
                <span>Founding Employee & Head of Marketing</span>, Invention
                Development Fund
              </p>
              <p>
                <span>Partner</span>, K2 Services Venture Capital
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-taka.jpg" alt="team-taka" />
            </div>
            <h5>Taka Osaki</h5>
            <h5>Managing Director</h5>
            <div className="desc">
              <p>
                <span>CGO</span>, ADK holdings
              </p>
              <p>
                <span>VP Head of Asia</span>, Netflix Inc.
              </p>
              <p>
                <span>Executive VP</span>, WarnerBros. North Asia
              </p>
              <p>
                <span>Director</span>, Nike Inc.
              </p>
              <p>
                <span>Director</span>, Apple Inc.
              </p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}></Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="text-center">
              <img src="/images/team-yuki.jpg" alt="team-yuki" />
            </div>
            <h5>Yuki Nakamura</h5>
            <h5>Director of Operations</h5>
            <div className="desc">
              <p>
                <span>Director</span>, Northport Commodities Pte Ltd
              </p>
              <p>
                <span>Senior Analyst</span>, Cerberus Japan
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
